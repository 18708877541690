<template>
  <div>
    <Banner v-if="isReady" :prismicDocument="prismicDocument"/>
    <project-information v-model="project"/>
    <available-plans @click="choosenPlan" />
    <billing-information v-if="showView === 'billing'" />
    <talk-to-us v-if="showView === 'talkToUs'" />
    <div class="col-12 text-center my-2" v-if="showView === 'onlyBtn'">
      <button
        type="button"
        class="btn btn-lg btn-rounded px-4 me-md-2 btn-dark my-1"
        @click="onCreateProject"
      >
        Create Project
      </button>
    </div>
  </div>
</template>
<script>
import AvailablePlans from "../components/CreateProject/AvailablePlans.vue";
import Banner from "../components/CreateProject/Banner.vue";
import BillingInformation from "../components/CreateProject/BillingInformation.vue";
import ProjectInformation from "../components/CreateProject/ProjectInformation.vue";
import TalkToUs from "../components/CreateProject/TalkToUs.vue";
export default {
  components: {
    Banner,
    ProjectInformation,
    AvailablePlans,
    TalkToUs,
    BillingInformation,
  },
  name: "CreateProject",
  data() {
    return {
      showView: "",
      prismicDocument: null,
      subscriptions: null,
      isReady: false,
      project: { name: '', role: 0, displayName: ''}
    };
  },
  methods: {
    choosenPlan(i) {
      this.showView = i.showView;
    },
    setSiteTitle(title){
      document.title = title
    },
    async onCreateProject() {
      if (!this.project.name) {
        alert("Invalid project name: " + this.project.name)
        return
      }
      this.$http.post('/spaces', [{
        accountType: "Company",
        constraints: ["wysiwygEditor"],
        name: this.project.name,
        masterSpace: null,
        unitsOfMeasure: []
      }])
        .then(result => {
          if (result && result.status === 200) {
            this.$router.push({name: 'Home'});
          } else {
            console.log('Something went wrong');
          }
        })
        .catch(error => console.log(error))
    }
  },
  beforeCreate() {
    this.$prismic.client.query(
        [
          this.$prismic.Predicates.any("document.type", ["launchpad_subscriptions"]),
          this.$prismic.Predicates.any("document.tags", ["ziqni.app"]),
        ],
        null,
        (error, document) => {
          if (error) {
            console.error(`Received error ${error}`);
            return;
          }
          this.prismicDocument = document.results[0].data
          this.subscriptions = this.prismicDocument["subscription-options"]
          if(this.prismicDocument["site-title"] && this.prismicDocument["site-title"][0])
            this.setSiteTitle(this.prismicDocument["site-title"][0].text)
          this.isReady = true
        }
    )
  },
};
</script>
